<template>
  <div
    class="flex flex-col w-full h-full flex-1 bg-gradient-to-b from-red-700 to-red-900 text-yellow-50 items-center shadow-md"
  >
    <div
      v-if="full"
      class="flex flex-col items-center py-4 md:py-5 xl:py-6 2xl:py-7"
    >
      <div class="flex w-11/12 justify-evenly items-center">
        <img
          class="h-20 sm:h-28 lg:h-32 xl:h-44"
          src="../assets/logo-greatpriory.png"
        />
        <img
          class="h-20 sm:h-28 lg:h-32 xl:h-44"
          src="../assets/ppsea_logo.png"
        />
        <img
          class="h-20 sm:h-28 lg:h-32 xl:h-44"
          src="../assets/kt-right.png"
        />
      </div>
      <div
        class="flex flex-row items-center content-center w-11/12 justify-evenly"
      >
        <div class="flex-auto flex flex-col flex-2 items-center justify-center">
          <div class="mx-1 place-content-center flex flex-col py-4 xl:py-6">
            <p
              class="font-uncial text-xl md:text-3xl xl:text-5xl text-center xl:leading-tight pb-2"
            >
              Provincial Priory of South East Asia
            </p>
            <p class="font-serif italic text-xs md:text-lg text-center">
              Under The Great Priory of the United Religious, Military and
              Masonic Orders of the Temple and of St. John of Jerusalem,
              Palestine, Rhodes and Malta of England and Wales and its Provinces
              Overseas
            </p>
          </div>
          <div class="w-full flex justify-evenly">
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/soepp_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/gcpp_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/kt_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/siam_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/star_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/sseapp_logo.png"
            />
            <img
              class="h-12 md:h-20 lg:h-32 w-auto"
              src="../assets/invictus_logo.png"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!full"
      class="flex w-11/12 py-2 px-1 xl:px-2 2xl:px-2 justify-center sm:justify-between"
    >
      <div class="flex flex-col flex-2">
        <p
          class="flex flex-2 items-center justify-center font-uncial text-lg md:text-2xl xl:text-3xl text-center xl:leading-tight"
        >
          Provincial Priory of South East Asia
        </p>
      </div>
      <div
        class="hidden sm:flex flex-1 justify-evenly align-center items-center"
      >
        <img class="h-16" src="../assets/logo-greatpriory.png" />
        <img class="h-16" src="../assets/ppsea_logo.png" />
        <img class="h-16" src="../assets/kt-right.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Banner",
  data() {
    return {
      active: [{ a: false }],
    };
  },
  props: {
    full: Boolean,
  },
};
</script>
<style>
@media only screen and (min-width: 640px) {
  .hover_active {
    background-color: rgba(17, 24, 39, 0.25);
  }
}

@media only screen and (min-width: 768px) {
  .hover_active {
    background-color: rgba(17, 24, 39, 0.25);
  }
}

@media only screen and (min-width: 1024px) {
  .hover_active {
    background-color: rgba(17, 24, 39, 0.25);
  }
}
</style>
