<template>
  <modal
    name="folder-upload"
    transition="pop-out"
    :adaptive="true"
    :focus-trap="true"
    height="auto"
    width="80%"
  >
    <div class="box">
      <div class="box-part" id="bp-left">
        <div class="partition" id="partition-register">
          <div class="partition-title">
            CREATE FOLDER
          </div>
          <form class="partition-form">
            <input
              type="text"
              class="w-full text-base leading-loose rounded-md border-gray-300 p-1 my-4"
              v-model="folderName"
              placeholder="Folder Name"
              @keydown="$event.stopImmediatePropagation()"
            />
            <input
              type="text"
              class="w-full text-base leading-loose rounded-md border-gray-300 p-1 my-4"
              v-model="description"
              placeholder="Description"
              @keydown="$event.stopImmediatePropagation()"
            />
            <div class="flex justify-center py-4 px-4">
              <button
                type="submit"
                :disabled="folderName.length == 0"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300 mr-2"
                @click.stop.prevent="createFolder"
              >
                Create
              </button>
              <button
                type="submit"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300"
                @click.stop.prevent="dismissModal"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import api from "@/api/apiServices";

export default {
  name: "FolderUploadModal",
  props: ["preceptoryId", "pageType", "currentFolder", "user"],
  data() {
    return {
      folderName: "",
      description: "",
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    async createFolder() {
      var params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        parentDir: this.currentFolder,
        folderName: this.folderName + "/",
        description: this.description,
        creator: this.user.attributes.name,
      };
      try {
        this.showLoader();
        api.createFolder(params).then((res) => {
          if (res) {
            this.hideLoader();
            this.$fire({
              title: "Folder created",
              type: "success",
              timer: 3000,
            }).then(() => {
              this.dismissModal();
            });
          }
        });
      } catch (err) {
        this.hideLoader();
        this.$fire({
          title: "Failed to create folder",
          text: err.message,
          type: "error",
        });
      }
    },
    dismissModal() {
      this.$modal.hide("folder-upload");
      this.folderName = "";
      this.description = "";
      this.$emit("dismissFolder");
    },
  },
};
</script>

<style>
.modal-footer {
  padding: 15px 0px 0px 0px;
  border-top: 1px solid #e5e5e5;
  margin-left: -14px;
  margin-right: -14px;
}
</style>
