<template>
  <modal
    name="docViewerModal"
    transition="pop-out"
    :adaptive="true"
    :focus-trap="true"
    height="100%"
    width="100%"
  >
    <font-awesome-icon
      v-if="docType !== 'pdf'"
      :icon="['fas', 'times']"
      size="2x"
      color="gray"
      class="absolute top-2 right-2 z-50 cursor-pointer"
      @click="$modal.hide('docViewerModal')"
    />
    <div class="bg-black flex justify-end" v-if="docType === 'pdf'">
      <font-awesome-icon
        :icon="['fas', 'times']"
        size="2x"
        color="gray"
        class="absolute top-10 right-6 z-50 cursor-pointer"
        @click="$modal.hide('docViewerModal')"
      />
    </div>
    <VueDocPreview
      v-if="docType === 'office'"
      :value="encodeURIComponent(item.url)"
      type="office"
    />
    <div
      v-if="docType === 'image'"
      id="image-container"
      class="bg-black flex flex-col justify-center items-center w-full h-full shadow-md relative"
    >
      <img :src="item.url" class="h-full object-contain" />
      <div
        class="w-full text-black text-xl py-5 text-left bg-white px-5 shadow absolute bottom-0 opacity-90"
      >
        <div
          class="flex justify-between w-full md:flex-row sm:flex-col items-center xs:text-sm"
        >
          <span class="font-bold">{{ item.fileName }}</span>
          <span class="text-sm">{{ prettyFileSize }}</span>
        </div>
        <span class="font-light mt-2 xs:text-sm">{{ item.description }}</span>
      </div>
    </div>
    <div
      v-if="docType === 'video'"
      id="image-container"
      class="bg-black flex flex-col justify-center items-center w-full h-full shadow-md relative"
    >
      <div
        class="md:w-full sm:w-10/12 text-black text-xl py-5 text-left bg-gray-200 px-5 shadow absolute bottom-0 opacity-80 z-10"
      >
        <div
          class="flex justify-between w-full md:flex-row sm:flex-col items-start xs:text-sm"
        >
          <span class="font-bold w-10/12 break-all">{{ item.fileName }}</span>
          <span class="text-sm">{{ prettyFileSize }}</span>
        </div>
        <span class="font-light mt-2">{{ item.description }}</span>
      </div>
      <video
        controls
        autoplay="false"
        muted
        class="h-5/6 xs:h-3/6 object-contain"
      >
        <source :src="item.url" />
      </video>
    </div>
    <div
      v-if="docType === 'audio'"
      class="bg-black h-full w-full flex flex-col justify-center items-center"
    >
      <font-awesome-icon
        :icon="['fa', 'music']"
        size="6x"
        color="gray"
        class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center mb-10"
      />
      <audio controls autoplay class="w-12/12 sm:w-6/12" volume="0.5">
        <source :src="item.url" />
        Your browser does not support the audio element.
      </audio>
      <div
        class="md:w-full sm:w-10/12 text-black text-xl py-5 text-left bg-gray-200 px-5 shadow absolute bottom-0 opacity-80 z-10"
      >
        <div
          class="flex justify-between w-full md:flex-row sm:flex-col items-start xs:text-sm"
        >
          <span class="font-bold w-10/12 break-all">{{ item.fileName }}</span>
          <span class="text-sm">{{ prettyFileSize }}</span>
        </div>
        <span class="font-light mt-2">{{ item.description }}</span>
      </div>
    </div>
    <vue-pdf-app
      v-if="docType === 'pdf'"
      :pdf="item.url"
      :page-number="1"
    ></vue-pdf-app>
  </modal>
</template>
<script>
import {
  officeExtensions,
  imageExtensions,
  videoExtensions,
  audioExtensions,
} from "../constants";
import VueDocPreview from "vue-doc-preview";
import VuePdfApp from "vue-pdf-app";
import "vue-pdf-app/dist/icons/main.css";
const prettyBytes = require("pretty-bytes");

export default {
  name: "item-viewer",
  props: {
    item: {
      type: Object,
      required: false,
    },
  },
  components: {
    VueDocPreview,
    VuePdfApp,
  },
  data() {
    return {
      pdfLoading: false,
      pdfError: "",
      page: 1,
      numPages: 0,
    };
  },
  computed: {
    docType: function() {
      if (this.type) {
        if (officeExtensions.includes(this.type.toLowerCase())) return "office";
        else if (imageExtensions.includes(this.type.toLowerCase()))
          return "image";
        else if (videoExtensions.includes(this.type.toLowerCase()))
          return "video";
        else if (audioExtensions.includes(this.type.toLowerCase()))
          return "audio";
        else if (this.type.toLowerCase() === "pdf") return "pdf";
        return null;
      }
      return null;
    },
    type: function() {
      if (this.item.fileName) {
        const splittedName = this.item.fileName.split(".");
        const type = splittedName[splittedName.length - 1];
        return type;
      } else return null;
    },
    prettyFileSize: function() {
      return this.convertSize(this.item.fileSize);
    },
  },
  updated() {},
  mounted() {},
  methods: {
    prevPage() {
      return this.page > 1 ? this.page-- : 1;
    },
    nextPage() {
      return this.page < this.numPages ? this.page++ : 1;
    },
    convertSize(size) {
      return prettyBytes(size);
    },
  },
  // I'm just commenting it out cause it's quite useful for debugging the pdf viewer
  // watch: {
  //   value: function(newVal) {
  //     console.log("value", newVal);
  //   },
  //   pdfLoading: function(newVal) {
  //     console.log("pdfLoading", newVal);
  //   },
  //   page: function(newVal) {
  //     console.log("page", newVal);
  //   },
  //   pdfError: function(newVal) {
  //     console.log("pdfError", newVal);
  //   },
  // },
};
</script>

<style scoped lang="postcss">
.button {
  @apply text-black rounded bg-gray-300 px-5 py-2 hover:bg-gray-400 cursor-pointer hover:text-white active:bg-green-700 transition-all duration-300 select-none;
}
</style>
