import {
  officeExtensions,
  videoExtensions,
  imageExtensions,
  audioExtensions,
} from "../constants/index.js";

export const isOfficeFileCheck = function(item) {
  const filetype = item.fileName.split(".");
  if (officeExtensions.includes(filetype[filetype.length - 1].toLowerCase())) {
    return true;
  } else if (item.fileName.toLowerCase().includes(".pdf")) {
    return true;
  } else {
    return false;
  }
};
export const isVideoFileCheck = function(item) {
  const filetype = item.fileName.split(".");
  if (videoExtensions.includes(filetype[filetype.length - 1].toLowerCase())) {
    return true;
  } else {
    return false;
  }
};
export const isImageFileCheck = function(item) {
  const filetype = item.fileName.split(".");
  if (imageExtensions.includes(filetype[filetype.length - 1].toLowerCase())) {
    return true;
  } else {
    return false;
  }
};

export const isAudioFileCheck = function(item) {
  const filetype = item.fileName.split(".");
  if (audioExtensions.includes(filetype[filetype.length - 1].toLowerCase())) {
    return true;
  } else {
    return false;
  }
};

export const isFolder = function(item) {
  if (item.fileType == "folder") {
    return true;
  } else return false;
};
export const isLink = function(item) {
  if (item.fileType == "link") {
    return true;
  } else return false;
};
export const isYoutubeLink = function(item) {
  if (item.fileType == "youtube") {
    return true;
  } else return false;
};
