<template>
  <div>
    <item-detail-modal
      :item="itemFocus"
      :getPermission="getPermission"
      :pageType="pageType"
      v-on:update:item="updateDetail"
    />
    <div class="flex flex-wrap justify-between gap-2 lg:gap-4 p-2">
      <input
        type="text"
        class="text-black form-control rounded-sm mb-3 border-gray-300 w-full lg:w-3/12 italic"
        v-model="filter"
        :placeholder="'Search ' + pageType"
        @input="onFilter()"
        @keydown="$event.stopImmediatePropagation()"
      />
      <div class="flex justify-between gap-2 lg:gap-4">
        <div class="hidden md:block" v-if="getPermission(pageType, 'add') == 1">
          <button
            type="submit"
            @click.stop.prevent="$modal.show('folder-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'folder-plus']"
              size="lg"
              class="mr-2"
            />Create Folder
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'file-upload']"
              size="lg"
              class="mr-2"
            />Upload File
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('link-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              size="lg"
              class="mr-2"
            />Upload Link
          </button>
        </div>
        <div class="block md:hidden" v-if="getPermission(pageType, 'add') == 1">
          <button
            type="submit"
            @click.stop.prevent="$modal.show('folder-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'folder-plus']"
              size="lg"
              class="mr-2"
            />+ Folder
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'file-upload']"
              size="lg"
              class="mr-2"
            />+ File
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('link-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              size="lg"
              class="mr-2"
            />+ Link
          </button>
        </div>
        <div>
          <list-grid-toggle
            @TogglePressed="listViewToggle"
            :isListView="isListView"
          />
        </div>
      </div>
      <div class="flex flex-1 justify-end text-black py-2 items-center">
        Showing
        <dropdown
          arrow
          placement="left"
          class="text-black flex justify-center items-center hover:bg-gray-300 rounded-sm bg-gray-200 px-2 mx-2"
        >
          <template v-slot:button>
            <div>
              {{ itemsPerPage != 0 ? itemsPerPage : "All" }}
            </div>
          </template>
          <template v-slot:content>
            <div class="flex flex-row">
              <ul class="item-menu">
                <li @click="changeItemsPerPage(20)">20</li>
                <li @click="changeItemsPerPage(40)">40</li>
                <li @click="changeItemsPerPage(0)">All</li>
              </ul>
            </div>
          </template>
        </dropdown>
        entries
      </div>
    </div>
    <div class="flex justify-between">
      <button
        @click="scrollLeft()"
        v-if="showArrows"
        class="focus:outline-none hover:bg-gray-300"
      >
        <font-awesome-icon
          :icon="['fas', 'angle-left']"
          size="2x"
          color="grey"
          class="w-auto h-10 object-contain align-center mx-2 block lg:hidden"
        />
        <font-awesome-icon
          :icon="['fas', 'angle-left']"
          size="7x"
          color="grey"
          class="w-auto h-10 object-contain align-center mx-2 hidden lg:block"
        />
      </button>
      <button
        @click="scrollRight()"
        v-if="showArrows"
        class="focus:outline-none hover:bg-gray-300"
      >
        <font-awesome-icon
          :icon="['fas', 'angle-right']"
          size="2x"
          color="grey"
          class="w-auto h-10 object-contain align-center mx-2 block lg:hidden"
        />
        <font-awesome-icon
          :icon="['fas', 'angle-right']"
          size="7x"
          color="grey"
          class="w-auto h-10 object-contain align-center mx-2 hidden lg:block"
        />
      </button>
    </div>
    <div class="text-black flex my-2">
      <p v-if="filteredItems.length == 0">No items found.</p>
      <div
        class="overflow-x-auto datatable w-full"
        ref="content"
        v-if="filteredItems.length != 0"
      >
        <datatable
          name="tableDisplay"
          ref="datatableRef"
          :columns="columns"
          :data="filteredItems"
          :per-page="itemsPerPage"
          class="text-black text-sm xl:text-base 2xl:text-lg w-full"
        >
          <!-- Below Comment is required -->
          <!-- eslint-disable-next-line -->
          <template slot-scope="{ row, column }">
            <tr>
              <td class="lg:w-2/6 xl:w-2/6 2xl:w-2/6">
                <div class="flex justify-start sm:ml-2 lg:ml-10">
                  <button>
                    <div class="flex flex-row">
                      <div
                        class="flex flex-row w-full"
                        v-if="isFolder(row)"
                        v-on:click="updatePage(row.fileName)"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'folder-open']"
                          size="2x"
                          color="grey"
                          class="w-auto h-10 object-contain align-center"
                        />
                        <div class="text-left flex my-auto mx-4">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <div
                        v-else-if="isVideoFile(row)"
                        class="flex flex-row"
                        @click="changeIndex(row.id)"
                      >
                        <video
                          :src="row.url"
                          class="rounded h-10 w-10 lg:h-10 lg:w-10 object-cover"
                        ></video>
                        <div class="text-left flex my-auto mx-2">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <div
                        v-else-if="isImageFile(row)"
                        class="flex flex-row self-center"
                        @click="changeIndex(row.id)"
                      >
                        <img
                          :src="row.url"
                          class="rounded h-10 w-10 lg:h-10 lg:w-10 object-cover"
                        />
                        <div class="text-left flex my-auto mx-2">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <div
                        v-else-if="isYoutubeLink(row)"
                        class="flex flex-row self-center"
                        @click="changeIndex(row.id)"
                      >
                        <img
                          :src="getYoutubeThumbnail(row.url)"
                          class="rounded h-10 w-10 lg:h-10 lg:w-10 object-cover"
                        />
                        <div class="text-left flex my-auto mx-2">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <a
                        v-else-if="isLink(row)"
                        class="flex flex-row self-center"
                        :href="row.url"
                        target="_blank"
                      >
                        <font-awesome-icon
                          :icon="['fas', 'link']"
                          size="2x"
                          color="grey"
                          class="w-auto h-10 object-contain align-center"
                        />
                        <div class="text-left flex my-auto mx-2">
                          {{ row.fileName }}
                        </div>
                      </a>
                      <div
                        v-else-if="isOfficeFile(row)"
                        class="flex flex-row"
                        @click="viewItemEvent(row)"
                      >
                        <font-awesome-icon
                          :icon="['fas', getIcon(row.fileName)]"
                          size="3x"
                          :color="getIconColor(row.fileName)"
                          class="w-auto lg:h-10 2xl:h-10 object-contain px-1"
                        />
                        <div class="text-left flex my-auto mx-4">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <div
                        v-else-if="isAudioFile(row)"
                        class="flex flex-row"
                        @click="viewItemEvent(row)"
                      >
                        <font-awesome-icon
                          :icon="['fas', getIcon(row.fileName)]"
                          size="2x"
                          :color="getIconColor(row.fileName)"
                          class="w-auto lg:h-10 2xl:h-10 object-contain px-1"
                        />
                        <div class="text-left flex my-auto mx-4">
                          {{ row.fileName }}
                        </div>
                      </div>
                      <div v-else>
                        <a target="_blank" :href="row.url" download>
                          <div class="flex flex-row">
                            <font-awesome-icon
                              :icon="['fas', 'file-alt']"
                              size="3x"
                              :color="getIconColor(row.fileName)"
                              class="w-auto lg:h-10 2xl:h-10 object-contain px-1"
                            />
                            <div class="justify-start flex my-auto mx-2">
                              {{ row.fileName }}
                            </div>
                          </div>
                        </a>
                      </div>
                    </div>
                  </button>
                </div>
              </td>
              <td class="lg:w-2/6 xl:w-2/6 2xl:w-2/6">
                <div>
                  {{ row.description == undefined ? "-" : row.description }}
                </div>
              </td>
              <td class="lg:w-min xl:w-min 2xl:w-min">
                <div v-if="row.createdTime == undefined">-</div>
                <div v-else>
                  {{ formatDate(row.createdTime) }}
                </div>
              </td>
              <td class="lg:w-min xl:w-min 2xl:w-min">
                <div>
                  {{
                    row.fileName.includes(".xlsx") ||
                    row.fileName.includes(".xls")
                      ? "excel"
                      : row.fileName.includes(".docx") ||
                        row.fileName.includes(".doc")
                      ? "word"
                      : row.fileName.includes(".pptx") ||
                        row.fileName.includes(".ppt")
                      ? "powerpoint"
                      : row.fileType
                  }}
                </div>
              </td>
              <td class="lg:w-min xl:w-min 2xl:w-min">
                {{ row.fileSize ? convertSize(row.fileSize) : "-" }}
              </td>
              <td align="center p-5">
                <div class="flex justify-evenly">
                  <a
                    target="_blank"
                    :href="row.url"
                    class="button"
                    download
                    v-if="!isFolder(row)"
                  >
                    <button>
                      <font-awesome-icon
                        class="w-auto object-contain mx-2"
                        size="1x"
                        :icon="['fas', 'download']"
                      />
                    </button>
                  </a>
                  <button @click.stop.prevent="viewDetails(row)">
                    <font-awesome-icon
                      class="w-auto object-contain mx-2"
                      size="1x"
                      :icon="['fas', 'edit']"
                    />
                  </button>
                  <button
                    @click="deleteEvent(row)"
                    v-if="getPermission(pageType, 'delete') == 1"
                  >
                    <font-awesome-icon
                      class="w-auto object-contain mx-2"
                      size="1x"
                      :icon="['fas', 'trash-alt']"
                    />
                  </button>
                </div>
              </td>
            </tr>
          </template>
        </datatable>
        <div class="flex justify-end" v-if="filteredItems.length != 0">
          <div class="flex items-center mb-3 text-black">
            Page:
          </div>
          <datatable-pager
            table="tableDisplay"
            type="abbreviated"
          ></datatable-pager>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown";
import ListGridToggle from "./ListGridToggle.vue";
import { getIconName, getIconCol, formatDate } from "../utils/index";
import {
  isImageFileCheck,
  isVideoFileCheck,
  isOfficeFileCheck,
  isAudioFileCheck,
  isFolder,
  isLink,
  isYoutubeLink,
} from "../utils/fileTypeCheck";
import ItemDetailModal from "./ItemDetailModal";
const prettyBytes = require("pretty-bytes");

export default {
  name: "DataTable",
  props: [
    "filteredItems",
    "isListView",
    "getPermission",
    "pageType",
    "updatePage",
    "preceptoryId",
  ],
  components: {
    Dropdown,
    ListGridToggle,
    ItemDetailModal,
  },
  async mounted() {
    window.addEventListener("resize", this.onResize);
  },
  updated() {
    setTimeout(() => this.onResize(), 300);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  data() {
    return {
      formatDate,
      rows: [],
      showArrows: true,
      itemsPerPage: 20,
      filter: "",
      itemFocus: null,
      columns: [
        { label: "Name", field: "fileName" },
        { label: "Description", field: "description" },
        { label: "Created on", field: "createdTime" },
        { label: "Type", field: "fileType" },
        { label: "Size", field: "fileSize" },
        { label: "Actions", field: "" },
      ],
    };
  },
  methods: {
    updateDetail(params) {
      this.$emit("updateDetail", params);
    },
    onResize() {
      let content = this.$refs.content;
      if (content == undefined) {
        this.showArrows = false;
      } else {
        this.showArrows = content.clientWidth < content.scrollWidth;
      }
    },
    sideScroll(element, direction, speed, distance, step) {
      var scrollAmount = 0;
      var slideTimer = setInterval(function() {
        if (direction == "left") {
          element.scrollLeft -= step;
        } else {
          element.scrollLeft += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    },
    scrollLeft() {
      let content = this.$refs.content;
      this.sideScroll(content, "left", 20, 100, 40);
    },
    scrollRight() {
      let content = this.$refs.content;
      this.sideScroll(content, "right", 20, 100, 40);
    },
    changeItemsPerPage(count) {
      this.$refs.datatableRef.page = 1;
      this.itemsPerPage = count;
    },
    isOfficeFile(item) {
      return isOfficeFileCheck(item);
    },
    isVideoFile(item) {
      return isVideoFileCheck(item);
    },
    isImageFile(item) {
      return isImageFileCheck(item);
    },
    isAudioFile(item) {
      return isAudioFileCheck(item);
    },
    isFolder(item) {
      return isFolder(item);
    },
    isLink(item) {
      return isLink(item);
    },
    isYoutubeLink(item) {
      return isYoutubeLink(item);
    },
    deleteEvent(item) {
      this.$emit("deletePressed", item);
    },
    viewItemEvent(item) {
      this.$emit("viewItemPressed", item);
    },
    listViewToggle() {
      this.$emit("viewToggle");
    },
    changeIndex(item) {
      this.$emit("changeIndex", item);
    },
    getIcon(name) {
      return getIconName(name);
    },
    getIconColor(name) {
      return getIconCol(name);
    },
    viewDetails(item) {
      this.itemFocus = item;
      this.itemFocus.oldFileName = this.itemFocus.fileName;
      this.itemFocus.oldDescription = this.itemFocus.description;
      this.$modal.show("item-detail");
    },
    onFilter() {
      this.$emit("filter", this.filter);
    },
    convertSize(size) {
      return prettyBytes(size);
    },
    getYoutubeThumbnail(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      var videoId = match && match[7].length == 11 ? match[7] : false;
      var baseUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`;
      return baseUrl;
    },
  },
};
</script>

<style lang="css">
th {
  background-color: #3a4750;
  color: white;
  border: 1px solid;
  border-color: white;
}
</style>
<style scoped lang="postcss">
.item-menu {
  @apply py-1;
}
.item-menu > li {
  @apply p-2 text-base text-black hover:bg-gray-200 cursor-pointer transition-all duration-300 select-none;
}
.datatable::-webkit-scrollbar {
  -webkit-appearance: none;
}

.datatable::-webkit-scrollbar:horizontal {
  height: 12px;
}

.datatable::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.datatable::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}
</style>
