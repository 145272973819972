const officeExtensions = ["ppt", "doc", "xls", "docx", "pptx", "xlsx"];
const videoExtensions = ["mp4", "mov", "ogg", "webm"];
const imageExtensions = ["png", "jpeg", "jpg", "tiff", "gif"];
const audioExtensions = ["mp3", "wav"];
const otherExtensions = ["pdf"];
const supportedExtensions = [
  ...officeExtensions,
  ...imageExtensions,
  ...videoExtensions,
  ...audioExtensions,
  ...otherExtensions,
];
const categoriesName = {
  Galleries: "Galleries",
  Charities: "Charitable Events",
  Articles: "Library",
  Presentations: "Presentations",
  Summons: "Summons",
};
const categoriesRoute = {
  Galleries: "Photo",
  Charities: "Charity",
  Articles: "Library",
  Presentations: "Presentation",
  Summons: "Summon",
};
export {
  supportedExtensions,
  officeExtensions,
  imageExtensions,
  videoExtensions,
  audioExtensions,
  otherExtensions,
  categoriesName,
  categoriesRoute,
};
