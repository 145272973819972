<template>
  <div class="flex flex-col bg-white z-10">
    <banner :full="full" />
    <navbar />
    <breadcrumb v-if="breadcrumb" />
  </div>
</template>

<script>
import { Banner, Navbar, Breadcrumb } from "../components";

export default {
  name: "HomeHeader",
  components: {
    Banner,
    Navbar,
    Breadcrumb,
  },
  props: {
    breadcrumb: Boolean,
    full: Boolean,
  },
};
</script>
