<template>
  <div>
    <item-detail-modal
      :item="itemFocus"
      v-on:update:item="updateDetail"
      :getPermission="getPermission"
      :pageType="pageType"
    />
    <div class="flex flex-wrap justify-between gap-2 lg:gap-4 p-2">
      <input
        type="text"
        class="text-black form-control rounded-sm mb-3 border-gray-300 w-full lg:w-3/12 italic"
        v-model="filter"
        :placeholder="'Search ' + pageType"
        @input="onFilter()"
        @keydown="$event.stopImmediatePropagation()"
      />
      <div class="flex justify-between gap-2 lg:gap-4">
        <div class="hidden md:block" v-if="getPermission(pageType, 'add') == 1">
          <button
            type="submit"
            @click.stop.prevent="$modal.show('folder-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'folder-plus']"
              size="lg"
              class="mr-2"
            />Create Folder
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'file-upload']"
              size="lg"
              class="mr-2"
            />Upload File
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('link-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              size="lg"
              class="mr-2"
            />Upload Link
          </button>
        </div>
        <div class="block md:hidden" v-if="getPermission(pageType, 'add') == 1">
          <button
            type="submit"
            @click.stop.prevent="$modal.show('folder-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'folder-plus']"
              size="lg"
              class="mr-2"
            />+ Folder
          </button>
          <button
            type="submit"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
            @click.stop.prevent="$modal.show('upload')"
          >
            <font-awesome-icon
              :icon="['fas', 'file-upload']"
              size="lg"
              class="mr-2"
            />+ File
          </button>
          <button
            type="submit"
            @click.stop.prevent="$modal.show('link-upload')"
            class="font-bold bg-red-600 hover:bg-green-500 px-3 py-2 inline-flex items-center content-end mb-2 mr-2 shadow-lg rounded-sm"
          >
            <font-awesome-icon
              :icon="['fas', 'link']"
              size="lg"
              class="mr-2"
            />+ Link
          </button>
        </div>
        <div>
          <list-grid-toggle
            @TogglePressed="listViewToggle"
            :isListView="isListView"
          />
        </div>
      </div>
      <div class="flex flex-1 justify-end text-black py-2 items-center">
        Sort by
        <dropdown
          arrow
          placement="right"
          class="text-black flex justify-center items-center hover:bg-gray-300 rounded-sm bg-gray-200 px-1 mx-2"
        >
          <template v-slot:button>
            <div>
              {{ sortBy }}
            </div>
          </template>
          <template v-slot:content>
            <div class="flex flex-row">
              <ul class="item-menu">
                <li @click="onSort()">
                  Type
                </li>
                <li @click="onSort('A-Z')">
                  A-Z
                </li>
                <li @click="onSort('Z-A')">
                  Z-A
                </li>
              </ul>
            </div>
          </template>
        </dropdown>
      </div>
    </div>
    <div class="text-black flex my-2">
      <p v-if="filteredItems.length == 0">No items found.</p>
      <ul
        class="text-black grid grid-flow-row gap-4 lg:gap-8 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 justify-center w-full"
        v-if="filteredItems.length > 0"
      >
        <div
          class="flex flex-col justify-between rounded-xl bg-white border shadow"
          v-for="(item, itemId) in filteredItems"
          :key="itemId"
        >
          <div class="flex justify-center cursor-pointer hover:bg-gray-300">
            <font-awesome-icon
              v-on:click="updatePage(item.fileName)"
              v-if="isFolder(item)"
              :icon="['fas', 'folder-open']"
              size="4x"
              color="grey"
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center"
            />
            <video
              v-else-if="isVideoFile(item)"
              :src="item.url"
              @click="changeIndex(item.id)"
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center p-1"
            ></video>
            <img
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center p-1"
              v-else-if="isImageFile(item)"
              :src="item.url"
              @click="changeIndex(item.id)"
            />
            <img
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center p-1"
              v-else-if="isYoutubeLink(item)"
              :src="getYoutubeThumbnail(item.url)"
              @click="changeIndex(item.id)"
            />
            <a v-else-if="isLink(item)" :href="item.url" target="_blank">
              <font-awesome-icon
                :icon="['fas', 'link']"
                size="4x"
                class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain"
              />
            </a>
            <font-awesome-icon
              v-else-if="isOfficeFile(item)"
              :icon="['fas', getIcon(item.fileName)]"
              size="4x"
              :color="getIconColor(item.fileName)"
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain"
              @click="viewItemEvent(item)"
            />
            <font-awesome-icon
              v-else-if="isAudioFile(item)"
              :icon="['fas', getIcon(item.fileName)]"
              size="4x"
              :color="getIconColor(item.fileName)"
              class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain"
              @click="viewItemEvent(item)"
            />
            <div v-else>
              <a target="_blank" :href="item.url" download>
                <div class="flex flex-row">
                  <font-awesome-icon
                    :icon="['fas', 'file-alt']"
                    size="4x"
                    :color="getIconColor(item.fileName)"
                    class="w-auto h-24 md:h-24 lg:h-32 2xl:h-32 object-contain align-center"
                  />
                </div>
              </a>
            </div>
          </div>
          <dropdown
            placement="right"
            v-tooltip="{
              placement: 'bottom',
              content: item.fileName,
              offset: '0px',
            }"
            class="rounded-b-xl p-2 place-content-center flex flex-1 hover:bg-gray-300"
          >
            <template v-slot:button>
              <p
                class="text-sm lg:text-base 2xl:text-lg text-center flex-2"
                v-line-clamp:20="2"
              >
                {{ item.fileName }}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path
                  d="M12 18c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3zm0-9c1.657 0 3 1.343 3 3s-1.343 3-3 3-3-1.343-3-3 1.343-3 3-3z"
                />
              </svg>
            </template>
            <template v-slot:content>
              <div class="flex flex-row">
                <ul class="item-menu" ref="testList">
                  <li @click.stop.prevent="viewDetails(item)">
                    Details
                  </li>
                  <li v-if="!isFolder(item)">
                    <a target="_blank" :href="item.url" class="button" download
                      >Download</a
                    >
                  </li>
                  <li
                    v-if="getPermission(pageType, 'delete') == 1"
                    class="cursor-pointer"
                    @click.stop.prevent="deleteEvent(item)"
                  >
                    Delete
                  </li>
                </ul>
              </div>
            </template>
          </dropdown>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import Dropdown from "./Dropdown";
import ListGridToggle from "./ListGridToggle.vue";
import { getIconName, getIconCol } from "../utils/index";
import {
  isImageFileCheck,
  isVideoFileCheck,
  isOfficeFileCheck,
  isAudioFileCheck,
  isFolder,
  isLink,
  isYoutubeLink,
} from "../utils/fileTypeCheck";
import ItemDetailModal from "./ItemDetailModal";
const prettyBytes = require("pretty-bytes");

export default {
  components: { Dropdown, ItemDetailModal, ListGridToggle },

  props: [
    "filteredItems",
    "isListView",
    "getPermission",
    "updatePage",
    "preceptoryId",
    "pageType",
    "sortBy",
  ],
  data() {
    return {
      itemFocus: null,
      filter: "",
    };
  },
  methods: {
    isOfficeFile(item) {
      return isOfficeFileCheck(item);
    },
    isVideoFile(item) {
      return isVideoFileCheck(item);
    },
    isImageFile(item) {
      return isImageFileCheck(item);
    },
    isAudioFile(item) {
      return isAudioFileCheck(item);
    },
    isFolder(item) {
      return isFolder(item);
    },
    isLink(item) {
      return isLink(item);
    },
    isYoutubeLink(item) {
      return isYoutubeLink(item);
    },
    updateDetail(params) {
      this.$emit("updateDetail", params);
    },
    deleteEvent(item) {
      this.$emit("deletePressed", item);
    },
    viewItemEvent(item) {
      this.$emit("viewItemPressed", item);
    },
    listViewToggle() {
      this.$emit("viewToggle");
    },
    changeIndex(item) {
      this.$emit("changeIndex", item);
    },
    getIcon(name) {
      return getIconName(name);
    },
    getIconColor(name) {
      return getIconCol(name);
    },
    viewDetails(item) {
      this.itemFocus = item;
      this.itemFocus.oldFileName = this.itemFocus.fileName;
      this.itemFocus.oldDescription = this.itemFocus.description;
      this.itemFocus.oldUrl = this.itemFocus.url;
      this.$modal.show("item-detail");
    },
    onFilter() {
      this.$emit("filter", this.filter);
    },
    onSort(sortString) {
      this.$emit("sort", sortString);
    },
    convertSize(size) {
      return prettyBytes(size);
    },
    getYoutubeThumbnail(url) {
      var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      var videoId = match && match[7].length == 11 ? match[7] : false;
      var baseUrl = `https://img.youtube.com/vi/${videoId}/default.jpg`;
      return baseUrl;
    },
  },
};
</script>

<style src="../styles/tooltip.css"></style>
<style scoped lang="postcss">
.item-menu {
  @apply py-1;
}
.item-menu > li {
  @apply p-2 text-base text-black hover:bg-gray-200 cursor-pointer transition-all duration-300 select-none;
}
</style>
