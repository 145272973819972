<template>
  <modal
    name="item-detail"
    transition="pop-out"
    :adaptive="true"
    :focus-trap="true"
    height="auto"
    width="90%"
    styles="border-radius: 0.375rem;"
  >
    <div class="text-gray-800">
      <div class="p-2">
        <div
          class="text-xl lg:text-3xl font-semibold text-center my-2 tracking-wide"
        >
          File details
        </div>
        <div
          v-if="data != null"
          class="flex flex-col justify-center py-4 px-2 sm:px-4 md:px-6 lg:px-8 text-sm sm:text-tiny lg:text-base"
        >
          <div class="py-1 md:py-2 flex" v-if="data.fileType == 'folder'">
            <span class="text-black font-medium text-base pr-2">Name: </span>
            <label-edit
              v-if="getPermission(pageType, 'update') == 1"
              folder
              :text="data.fileName"
              id="labeledit1"
              v-on:text-updated="nameUpdated"
              class="flex"
            ></label-edit>
            <span v-else> {{ data.fileName }}</span>
          </div>
          <div class="py-1 md:py-2 flex" v-else>
            <span class="text-black font-medium text-base pr-2">Name: </span>
            <label-edit
              v-if="getPermission(pageType, 'update') == 1"
              file
              :text="data.fileName"
              id="labeledit1"
              v-on:text-updated="nameUpdated"
              class="flex"
            ></label-edit>
            <span v-else> {{ data.fileName }}</span>
          </div>
          <div class="py-1 md:py-2 flex">
            <span class="text-black font-medium text-base pr-2"
              >Description:
            </span>
            <label-edit
              v-if="getPermission(pageType, 'update') == 1"
              :text="data.description"
              id="labeledit2"
              v-on:text-updated="descriptionUpdated"
              class="flex"
            ></label-edit>
            <span v-else>
              {{ data.description ? data.description : "N/A" }}</span
            >
          </div>
          <div
            class="py-1 md:py-2 flex"
            v-if="data.fileType == 'youtube' || data.fileType == 'link'"
          >
            <span class="text-black font-medium text-base pr-2">URL: </span>
            <label-edit
              v-if="getPermission(pageType, 'update') == 1"
              :text="data.url"
              id="labeledit3"
              v-on:text-updated="urlUpdated"
              class="flex"
            ></label-edit>
            <span v-else> {{ data.url ? data.url : "N/A" }}</span>
          </div>
          <div class="py-1 md:py-2" v-if="data.fileSize">
            <span class="text-black font-medium text-base pr-2">Size: </span>
            {{ convertSize(data.fileSize) }}
          </div>
          <div class="py-1 md:py-2" v-if="data.fileType">
            <span class="text-black font-medium text-base pr-2">Type: </span>
            {{ data.fileType }}
          </div>
          <div class="py-1 md:py-2" v-if="data.createdBy">
            <span class="text-black font-medium text-base pr-2"
              >Created by:
            </span>
            {{ data.createdBy }}
          </div>
          <div class="py-1 md:py-2" v-if="data.createdTime">
            <span class="text-black font-medium text-base pr-2"
              >Creation time:
            </span>
            {{ getLocaleString(data.createdTime) }}
          </div>
        </div>
      </div>
      <div class="flex text-center tracking-wide">
        <button
          @click="dismissModal()"
          class="bg-gray-200 flex-1 px-2 py-3 focus:outline-none hover:bg-gray-300 text-red-700 font-bold"
        >
          Close
        </button>
        <button
          @click="saveItem()"
          class="bg-red-600 flex-1 px-2 py-3 focus:outline-none hover:bg-red-700 text-white font-bold"
        >
          Save
        </button>
      </div>
    </div>
  </modal>
</template>
<script>
import LabelEdit from "./LabelEdit.vue";
import moment from "moment";
const prettyBytes = require("pretty-bytes");

export default {
  name: "DetailModal",
  props: ["item", "getPermission", "pageType"],
  data() {
    return {
      text: "",
      data: undefined,
    };
  },
  components: {
    LabelEdit,
  },
  watch: {
    item: function() {
      if (this.item) this.data = JSON.parse(JSON.stringify(this.item));
    },
  },
  methods: {
    nameUpdated(text) {
      this.data.fileName = text;
    },
    descriptionUpdated(text) {
      this.data.description = text;
    },
    urlUpdated(text) {
      this.data.url = text;
    },
    saveItem() {
      this.$emit("update:item", this.data);
      this.dismissModal();
    },
    dismissModal() {
      this.$modal.hide("item-detail");
    },
    /* eslint-enable no-unused-vars */
    convertSize(size) {
      return prettyBytes(size);
    },
    getLocaleString(date) {
      var tempDate = moment.utc(date);
      return tempDate.local().format("MMMM Do YYYY, h:mm:ss a");
    },
  },
};
</script>
