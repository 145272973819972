<template>
  <div>
    <div>
      <!-- LIST BUTTON -->
      <button
        v-if="isListView === true"
        class="text-white text-sm sm:text-base bg-white border-red-500 font-bold px-3 inline-flex items-center mr-2 focus:outline-none hover:bg-gray-300"
        @click="togglePressed()"
      >
        <font-awesome-icon
          :icon="['fas', 'list']"
          size="lg"
          color="red"
          class="w-auto h-10 object-contain align-center"
        />
      </button>
      <!-- GRID BUTTON -->
      <button
        v-else
        class="text-white text-sm sm:text-base bg-white border-red-500 font-bold px-3 inline-flex items-center mr-2 focus:outline-none hover:bg-gray-300"
        @click="togglePressed()"
      >
        <font-awesome-icon
          :icon="['fas', 'th']"
          size="lg"
          color="red"
          class="w-auto h-10 object-contain align-center"
        />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["isListView"],
  data() {
    return {};
  },
  methods: {
    togglePressed() {
      this.$emit("TogglePressed");
    },
  },
};
</script>

<style scoped></style>
