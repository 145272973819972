<template>
  <div
    class="h-auto w-full bg-gradient-to-b from-red-700 to-red-900 flex flex-col justify-center text-yellow-50"
  >
    <div v-if="!isAuth" class="h-full my-2 flex justify-center space-x-4 pt-2">
      <img class="h-6 md:h-9 lg:h-12 w-auto" src="../assets/sg_flag.png" />
      <img class="h-6 md:h-9 lg:h-12 w-auto" src="../assets/my_flag.png" />
      <img class="h-6 md:h-9 lg:h-12 w-auto" src="../assets/th_flag.png" />
    </div>
    <p
      v-if="!isAuth"
      class="text-center text-base md:text-lg lg:text-2xl p-2 font-serif"
    >
      We currently have 7 preceptories across Malaysia, Singapore and Thailand.
    </p>
    <div v-if="isAuth" class="h-full my-2 flex justify-center space-x-4 pt-2">
      <img class="h-6 md:h-8 lg:h-10 w-auto" src="../assets/sg_flag.png" />
      <img class="h-6 md:h-8 lg:h-10 w-auto" src="../assets/my_flag.png" />
      <img class="h-6 md:h-8 lg:h-10 w-auto" src="../assets/th_flag.png" />
    </div>
    <p
      v-if="isAuth"
      class="text-center text-tiny md:text-base lg:text-lg p-2 font-serif"
    >
      We currently have 7 preceptories across Malaysia, Singapore and Thailand.
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeFooter",
  data() {
    return {
      isAuth: this.$store.getters["security/isAuthenticated"],
    };
  },
};
</script>
