<template>
  <modal
    name="upload"
    transition="pop-out"
    :adaptive="false"
    :focus-trap="true"
    height="auto"
    width="1200"
    :scrollable="true"
  >
    <div class="box">
      <div class="box-part" id="bp-left">
        <div class="partition" id="partition-register">
          <div class="partition-title">
            UPLOAD FILE(S)
          </div>
          <button
            class="float-right text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold px-3 py-2 mr-4 mb-4 rounded-sm items-center content-end focus:outline-none"
            @click.stop.prevent="chooseFile"
          >
            Add file
          </button>
          <datatable
            :columns="columns"
            :data="rows"
            :filter="filter"
            :per-page="4"
            class="text-black text-sm xl:text-tiny 2xl:text-base px-2"
          >
            <!-- eslint-disable-next-line -->
            <template slot-scope="{ row, columns, index }">
              <tr>
                <td>
                  <textarea-autosize
                    ref="text1"
                    v-model="row.shortname"
                    placeholder="Name"
                    :min-height="30"
                    class="border w-full px-2 py-1 text-sm xl:text-tiny 2xl:text-base"
                  />
                </td>
                <td>
                  <textarea-autosize
                    ref="text2"
                    placeholder="Description"
                    v-model="row.description"
                    :min-height="30"
                    class="border w-full px-2 py-1 text-sm xl:text-tiny 2xl:text-base"
                  />
                </td>
                <td>{{ row.type }}</td>
                <td v-show="!isMobile">{{ convertSize(row.size) }}</td>
                <td v-show="!isMobile">
                  <font-awesome-icon
                    :icon="['fas', 'check']"
                    v-if="row.status == 1"
                    class="text-green-400"
                  />
                  <font-awesome-icon
                    :icon="['fas', 'times']"
                    v-if="row.status == 0"
                    class="text-red-400"
                  />
                </td>
                <td>
                  <div>
                    <button v-on:click="removeItem(index)">
                      <font-awesome-icon
                        :icon="['fas', 'trash-alt']"
                        class="text-red-400"
                      />
                    </button>
                  </div>
                </td></tr
            ></template>
          </datatable>
          <datatable-pager v-model="page" type="short" class="text-base">
          </datatable-pager>
          <form class="partition-form flex flex-col">
            <input
              id="fileUpload"
              type="file"
              ref="file"
              hidden
              :accept="
                pageType == 'Galleries' ? photoExtensions : allExtensions
              "
              multiple
              @change="onChange"
            />
            <!-- display when all sucessfully upload -->
            <div
              class="flex justify-center pt-2 pb-4 px-4"
              v-if="requireOverwrite() == 'done'"
            >
              <button
                type="submit"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300 mr-2"
                @click.stop.prevent="dismissModal"
              >
                Done
              </button>
            </div>
            <!-- display when need to be upload -->
            <div
              class="flex justify-center pt-2 pb-4 px-4"
              v-if="requireOverwrite() != 'done'"
            >
              <button
                type="submit"
                :disabled="rows.length == 0"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300 mr-2"
                @click.stop.prevent="uploadFiles"
              >
                Upload
              </button>
              <button
                type="submit"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300"
                @click.stop.prevent="dismissModal"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { Storage } from "aws-amplify";
import api from "@/api/apiServices";
const prettyBytes = require("pretty-bytes");

export default {
  name: "UploadModal",
  props: ["preceptoryId", "pageType", "currentFolder", "user"],
  data() {
    return {
      photoExtensions: ".png, .jpeg, .jpg, .mp3, .mp4",
      allExtensions:
        ".pdf, .doc, .docx, .xlsx, .xls, .ppt, .pptx, .png, .jpeg, .jpg, .mp3, .mp4",
      isMobile: false,
      filter: "",
      page: 1,
      rows: [],
      modalWidth: 0,
      columns: [],
      columnsNormal: [
        {
          label: "Name",
          align: "center",
          headerClass: "font-semibold border-2 h-10",
          interpolate: true,
        },
        {
          label: "Description",
          align: "center",
          headerClass: " font-semibold border-2",
          interpolate: true,
        },
        {
          label: "Type",
          align: "center",
          headerClass: " font-semibold border-2",
          interpolate: true,
        },
        {
          label: "Size",
          align: "center",
          headerClass: " font-semibold border-2",
          interpolate: true,
        },
        {
          label: "Status",
          align: "center",
          headerClass: " font-semibold border-2",
          interpolate: true,
        },
        {
          label: "Action",
          align: "center",
          headerClass: " font-semibold border-2",
          interpolate: true,
        },
      ],
      columnsMobile: [],
      loader: null,
    };
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.columnsMobile = [...this.columnsNormal];
    this.columnsMobile.splice(3, 2);
    if (this.$vssWidth < 640) {
      this.isMobile = true;
      this.columns = this.columnsMobile;
    } else {
      this.isMobile = false;
      this.columns = this.columnsNormal;
    }
  },
  created() {
    this.modalWidth =
      window.innerWidth > 1024 ? (this.$vssWidth / 4) * 3 : this.$vssWidth;
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    async putFiles(files) {
      try {
        for (let i = 0; i < files.length; i++) {
          await this.fileUpload(files[i]);
        }
        for (let i = 0; i < files.length; i++) {
          const element = files[i];
          if (element.status == 0) {
            try {
              var res = await this.$confirm(
                `A file named "${element.name}" exists, do you want to overwrite?`
              );
              if (res) {
                await this.fileUpload(element);
                let temp = JSON.parse(JSON.stringify(files[i].file));
                this.rows[i].file = temp;
              }
              // eslint-disable-next-line
            } catch {}
          }
        }
        var filesUploaded = files.filter((x) => x.status == 1);
        if (filesUploaded.length == 0) {
          this.$fire("No files uploaded");
        } else {
          this.$fire("Files uploaded.");
        }
        this.dismissModal();
      } catch (err) {
        this.$fire({
          title: "Failed to upload",
          text: err.message,
          type: "error",
          timer: 3000,
        });
      }
    },
    async fileUpload(file) {
      var params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        folderName: this.currentFolder,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        overwrite: file.status != null ? true : false,
        creator: this.user.attributes.name,
        description: file.description,
      };
      let res = await api.createFile(params);
      if (res.data.Message == "File already exists.") {
        file.status = 0;
      } else {
        file.status = 1;
        var folder = `${this.pageType}/${this.preceptoryId}/${this.currentFolder}`;
        await Storage.put(`${folder}${file.name}`, file.file);
      }
    },
    /* eslint-disable no-unused-vars */
    onResize(event) {
      if (this.$vssWidth < 640) {
        this.isMobile = true;
        this.columns = this.columnsMobile;
      } else {
        this.isMobile = false;
        this.columns = this.columnsNormal;
      }
      if (this.$refs.text1) {
        this.$refs.text1.resize();
      }
      if (this.$refs.text2) {
        this.$refs.text2.resize();
      }
    },
    dismissModal() {
      this.$modal.hide("upload");
      this.rows = [];
      this.$emit("done");
    },
    /* eslint-enable no-unused-vars */
    convertSize(size) {
      return prettyBytes(size);
    },
    removeItem(index) {
      this.rows.splice(index, 1);
    },
    async uploadFiles() {
      this.showLoader();
      this.rows.forEach((item) => {
        item.name = item.shortname + item.type;
      });
      await this.putFiles(this.rows);
      this.hideLoader();
    },
    onChange(event) {
      var files = event.target.files;
      for (var i = 0; i < files.length; i++) {
        var item = {
          id: i,
          file: files[i],
          name: files[i].name,
          shortname: files[i].name
            .split(".")
            .slice(0, -1)
            .join("."),
          description: files[i].description ? files[i].description : "",
          size: files[i].size,
          type: "." + files[i].name.split(".").pop(),
        };
        this.rows.push(item);
      }
    },
    async chooseFile() {
      document.getElementById("fileUpload").click();
    },
    requireOverwrite() {
      var status = "";
      for (let i = 0; i < this.rows.length; i++) {
        const element = this.rows[i];
        if (element.status != null && element.status == 0) {
          status = "overwrite";
          break;
        }
        if (i == this.rows.length - 1 && element.status != null) {
          status = "done";
          break;
        }
      }
      return status;
    },
  },
};
</script>

<style src="../styles/table.css"></style>
<style lang="scss">
.box {
  background: white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;

  .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  .box-messages {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .box-error-message {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    height: 0;
    line-height: 32px;
    padding: 0 12px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: white;
    background: #f38181;
  }

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 2rem;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 1.5rem;
      font-weight: 400;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  input[type="text"] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}

.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
