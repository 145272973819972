<template>
  <div
    class="relative flex h-12 bg-gray-100 shadow-md px-3 lg:px-8 text-black font-semibold text-xxs sm:text-base 2xl:text-xl"
  >
    <div class="flex content-center lg:hidden">
      <button
        class="text-white font-bold uppercase text-sm rounded outline-none focus:outline-none m-1 ease-linear transition-all duration-150 font-normal rounded outline-none focus:outline-none capitalize w-full"
        type="button"
        v-on:click="toggleDropdown()"
        ref="btnDropdownRef"
      >
        <svg viewBox="0 0 100 80" width="24" height="100%">
          <rect width="100" height="15"></rect>
          <rect y="30" width="100" height="15"></rect>
          <rect y="60" width="100" height="15"></rect>
        </svg>
      </button>
      <div
        v-bind:class="{
          hidden: !dropdownPopoverShow,
          block: dropdownPopoverShow,
        }"
        class="bg-white text-base z-40 float-left p-2 list-none text-left rounded shadow-lg"
        ref="popoverDropdownRef"
      >
        <router-link to="/" exact class="block py-1 hover:text-gray-500"
          >Home
          <span class="sr-only">Home</span>
        </router-link>
        <router-link to="/about" exact class="block py-1">
          The Order
          <span class="sr-only">The Order</span>
        </router-link>
        <router-link v-show="isAuth" to="/priory" exact class="block py-1"
          >Provincial Priory
          <span class="sr-only">Provincial Priory</span>
        </router-link>
        <router-link v-show="isAuth" to="/preceptory" exact class="block py-1"
          >Preceptories
          <span class="sr-only">Preceptories</span>
        </router-link>
        <router-link
          v-show="isAuth"
          to="/member"
          class="block py-1"
          v-if="getPermission('Members', 'get') == 1"
        >
          Members
          <span class="sr-only">Members</span>
        </router-link>
        <router-link
          v-show="isAuth"
          to="/permission"
          class="block py-1"
          v-if="getPermission('Permissions', 'get') == 1"
        >
          Permissions
          <span class="sr-only">Permissions</span>
        </router-link>
      </div>
    </div>
    <div class="hidden lg:flex justify-start items-center">
      <router-link to="/" exact class="mr-4">
        <p>Home</p>
        <span class="sr-only">Home</span>
      </router-link>
      <router-link to="/about" exact class="mr-4">
        <p>The Order</p>
        <span class="sr-only">The Order</span>
      </router-link>
      <router-link v-show="isAuth" to="/priory" exact class="mr-4">
        <p>Provincial Priory</p>
        <span class="sr-only">Provincial Priory</span>
      </router-link>
      <router-link v-show="isAuth" to="/preceptory" exact class="mr-4">
        <p>Preceptories</p>
        <span class="sr-only">Preceptories</span>
      </router-link>
      <router-link
        v-show="isAuth"
        to="/member"
        class="mr-4"
        v-if="getPermission('Members', 'get') == 1"
      >
        <p>Members</p>
        <span class="sr-only">Members</span>
      </router-link>
      <router-link v-show="isAuth" to="/permission" class="mr-4">
        <p v-if="getPermission('Permissions', 'get') == 1">
          Permissions
        </p>
        <span class="sr-only">Permissions</span>
      </router-link>
    </div>
    <div class="flex-1 flex justify-end">
      <div class="ml-4 flex items-center md:ml-6">
        <div class="flex">
          <router-link
            v-show="!isAuth"
            to="/login"
            class="mr-4 hover:text-gray-500 text-base 2xl:text-xl"
          >
            Login
            <span class="sr-only">Login</span>
          </router-link>
        </div>
        <div v-if="isAuth" class="flex items-center">
          <img
            src="../assets/user.png"
            class="h-4 lg:h-6 w-4 lg:w-6 mr-1 lg:mr-2"
          />
          <p class="italic font-serif font-bold text-base 2xl:text-xl">
            Welcome
            {{
              this.user.attributes["custom:title"] +
                " " +
                this.user.attributes.name
            }}
          </p>
          <div class="ml-1 lg:ml-3 relative">
            <div v-on:click="isActive = !isActive">
              <button
                class="p-1 rounded-md text-sm lg:text-xl hover:text-gray-500 focus:outline-none"
                id="user-menu"
                aria-haspopup="true"
              >
                <span class="sr-only">Open user menu</span>
                &#9660;
              </button>
            </div>
            <!--
              Profile dropdown panel, show/hide based on dropdown state.

              Entering: "transition ease-out duration-100"
                From: "transform opacity-0 scale-95"
                To: "transform opacity-100 scale-100"
              Leaving: "transition ease-in duration-75"
                From: "transform opacity-100 scale-100"
                To: "transform opacity-0 scale-95"
            -->
            <div
              v-show="isActive"
              class="origin-top-right absolute z-10 right-0 mt-2 w-56 rounded-md shadow-lg py-1 bg-black ring-1 ring-black ring-opacity-5"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu"
            >
              <router-link
                to="/changePassword"
                class="block px-4 py-2 text-xs lg:text-base text-white hover:bg-gray-700 flex items-center content-center space-x-2"
                role="menuitem"
              >
                <img
                  src="../assets/padlock.png"
                  class="h-4 lg:h-6 w-4 lg:w-6"
                />
                <p class="font-semibold">Change password</p></router-link
              >
              <button
                class="block px-4 py-2 w-full text-xs lg:text-base text-white hover:bg-gray-700 flex items-center content-center space-x-2"
                role="menuitem"
                @click="logout"
              >
                <img src="../assets/logout.png" class="h-4 lg:h-6 w-4 lg:w-6" />
                <p class="font-semibold">Logout</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { createPopper } from "@popperjs/core";

export default {
  name: "Navbar",
  components: {},
  data() {
    return {
      isActive: false,
      isAuth: this.$store.getters["security/isAuthenticated"],
      user: this.$store.getters["security/currentUser"],
      permission: this.$store.getters["security/currentPermission"],
      dropdownPopoverShow: false,
    };
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut();
        this.$store.commit("security/purgeAuth");
        this.$fire({
          title: "Logout Successfully",
          type: "success",
          timer: 3000,
        });
        this.$router.push({ name: "Login" });
      } catch (error) {
        this.$fire({
          title: "Failed to create folder",
          text: error.message,
          type: "error",
        });
      }
    },
    getPermission(module, type) {
      for (let i = 0; i < this.permission.length; i++) {
        const element = this.permission[i];
        if (module == element.module) {
          return element[type];
        }
      }
    },
    toggleDropdown: function() {
      if (this.dropdownPopoverShow) {
        this.dropdownPopoverShow = false;
      } else {
        this.dropdownPopoverShow = true;
        createPopper(this.$refs.btnDropdownRef, this.$refs.popoverDropdownRef, {
          placement: "bottom-start",
        });
      }
    },
  },
};
</script>
