<template>
  <modal
    name="link-upload"
    transition="pop-out"
    :adaptive="true"
    :focus-trap="true"
    height="auto"
    width="1200"
    :scrollable="true"
  >
    <div class="box">
      <div class="box-part" id="bp-left">
        <div class="partition" id="partition-register">
          <div class="partition-title">
            UPLOAD LINK
          </div>
          <form class="partition-form">
            <input
              type="text"
              class="w-full text-base leading-loose rounded-md border-gray-300 p-1 my-4"
              v-model="row.name"
              placeholder="Name"
              @keydown="$event.stopImmediatePropagation()"
            />
            <input
              type="text"
              class="w-full text-base leading-loose rounded-md border-gray-300 p-1 my-4"
              v-model="row.description"
              placeholder="Description"
              @keydown="$event.stopImmediatePropagation()"
            />
            <input
              type="text"
              class="w-full text-base leading-loose rounded-md border-gray-300 p-1 my-4"
              v-model="row.url"
              placeholder="URL"
              @keydown="$event.stopImmediatePropagation()"
            />
            <input
              class="mr-1"
              type="radio"
              id="link"
              value="link"
              v-model="row.type"
            />
            <label class="radiotext" for="link">Link</label>
            <br />
            <input
              class="mr-1"
              type="radio"
              id="youtube"
              value="youtube"
              v-model="row.type"
            />
            <label class="radiotext" for="youtube">Youtube</label>
            <div class="flex justify-center py-4 px-4">
              <button
                type="submit"
                :disabled="row.name.length == 0 || row.url.length == 0"
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300 mr-2"
                @click.stop.prevent="uploadLink"
              >
                Create
              </button>
              <button
                class="text-white text-sm lg:text-lg text-center bg-red-600 hover:bg-green-500 font-semibold mx-3 px-3 py-2 rounded-sm justify-self-center disabled:bg-gray-300"
                @click.stop.prevent="dismissModal"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import api from "@/api/apiServices";

export default {
  name: "LinkUploadModal",
  props: ["preceptoryId", "pageType", "currentFolder", "user"],
  data() {
    return {
      filter: "",
      row: {
        url: "",
        name: "",
        description: "",
        size: 0,
        type: "link",
      },
      loader: null,
    };
  },
  methods: {
    showLoader() {
      this.loader = this.$loading.show({
        canCancel: false,
        onCancel: this.onCancel,
      });
    },
    hideLoader() {
      this.loader.hide();
    },
    async putFiles(file) {
      try {
        await this.fileUpload(file);
        const element = file;
        if (element.status == 0) {
          try {
            var res = await this.$confirm(
              `A file named "${element.name}" exists, do you want to overwrite?`
            );
            if (res) {
              await this.fileUpload(element);
            }
            // eslint-disable-next-line
          } catch {}
        }
        if (file.status == 1) {
          this.$fire("Link uploaded");
        } else {
          this.$fire("Link not uploaded.");
        }
        this.dismissModal();
      } catch (err) {
        this.$fire({
          title: "Failed to upload",
          text: err.message,
          type: "error",
          timer: 3000,
        });
      }
    },
    async fileUpload(file) {
      var params = {
        preceptoryId: this.preceptoryId,
        type: this.pageType,
        folderName: this.currentFolder,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        overwrite: file.status != null ? true : false,
        creator: this.user.attributes.name,
        description: file.description,
        url: file.url,
      };
      let res = await api.createFile(params);
      if (res.data.Message == "File already exists.") {
        file.status = 0;
      } else {
        file.status = 1;
      }
    },
    dismissModal() {
      this.$modal.hide("link-upload");
      this.row = {
        url: "",
        name: "",
        description: "",
        size: 0,
        type: "link",
      };
      this.$emit("done");
    },
    async uploadLink() {
      this.showLoader();
      await this.putFiles(this.row);
      this.hideLoader();
    },
  },
};
</script>
<style lang="scss">
.box {
  background: white;
  overflow: hidden;
  width: 100%;
  height: 100%;
  border-radius: 2px;
  box-sizing: border-box;
  box-shadow: 0 0 40px black;
  color: #8b8c8d;

  .box-part {
    display: inline-block;
    position: relative;
    vertical-align: top;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
  }

  .box-messages {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }

  .box-error-message {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    height: 0;
    line-height: 32px;
    padding: 0 12px;
    text-align: center;
    width: 100%;
    font-size: 11px;
    color: white;
    background: #f38181;
  }

  .partition {
    width: 100%;
    height: 100%;

    .partition-title {
      box-sizing: border-box;
      padding: 2rem;
      width: 100%;
      text-align: center;
      letter-spacing: 1px;
      font-size: 1.5rem;
      font-weight: 400;
    }

    .partition-form {
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  input[type="text"] {
    display: block;
    box-sizing: border-box;
    margin-bottom: 4px;
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    border-bottom: 1px solid #dddedf;
    padding: 4px 8px;
    font-family: inherit;
    transition: 0.5s all;
  }

  .radiotext {
    width: 100%;
    font-size: 12px;
    line-height: 2;
    border: 0;
    font-family: inherit;
    transition: 0.5s all;
  }
  .large-btn {
    width: 100%;
    background: white;

    span {
      font-weight: 600;
    }
    &:hover {
      color: white !important;
    }
  }
}
.pop-out-enter-active,
.pop-out-leave-active {
  transition: all 0.5s;
}

.pop-out-enter,
.pop-out-leave-active {
  opacity: 0;
  transform: translateY(24px);
}
</style>
