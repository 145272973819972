<template>
  <div class="px-3 lg:px-8 pt-2 bg-white flex">
    <button class="pr-4 focus:outline-none text-gray-100" @click="routeBack()">
      <img
        src="../assets/back-button.png"
        class="h-8 hover:bg-gray-300 rounded-full"
      />
    </button>
    <ul class="text-black text-base md:text-lg lg:text-xl" id="breadcrumbList">
      <li class="linked" @click="routeBase()">{{ type }}</li>
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{ linked: idx < breadcrumbList.length - 1 }"
      >
        {{ breadcrumb }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "FileBreadcrumb",
  props: {
    breadcrumbString: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: "",
      breadcrumbList: [],
      isActive: false,
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    breadcrumbString() {
      this.updateList();
    },
  },
  methods: {
    routeBase() {
      this.$emit("routeFiles", "");
    },
    routeTo(pRouteTo) {
      if (pRouteTo != this.breadcrumbList.length - 1) {
        this.url = this.breadcrumbList.slice(0, pRouteTo + 1).join("/") + "/";
        this.$emit("routeFiles", this.url);
      }
    },
    routeBack() {
      if (this.breadcrumbList.length <= 1) {
        this.routeBase();
      } else {
        this.routeTo(this.breadcrumbList.length - 2);
      }
    },
    updateList() {
      this.breadcrumbList = this.breadcrumbString.split("/");
      if (this.breadcrumbList) {
        if (this.breadcrumbList[this.breadcrumbList.length - 1] == "") {
          this.breadcrumbList = this.breadcrumbList.slice(0, -1);
        }
      }
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

ul > li {
  display: flex;
  float: left;
  width: auto;
  color: default;
  font-weight: bolder;
  cursor: default;
  align-items: center;
}

ul > li:not(:last-child)::after {
  content: "/";
  float: right;
  color: black;
  cursor: default;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.linked {
  cursor: pointer;
  font-weight: 500;
  color: red;
  font-style: italic;
}
</style>
