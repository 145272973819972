<template>
  <div class="px-3 lg:px-8 pt-2 bg-white flex">
    <button
      class="pr-4 focus:outline-none text-gray-100"
      @click="routeBack()"
      v-if="isActive"
    >
      <img
        src="../assets/back-button.png"
        class="h-8 hover:bg-gray-300 rounded-full"
      />
    </button>
    <ul class="text-black text-base md:text-lg lg:text-xl" id="breadcrumbList">
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        @click="routeTo(idx)"
        :class="{ linked: !!breadcrumb.link }"
      >
        {{ breadcrumb.name }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      breadcrumbList: [],
      isActive: false,
    };
  },
  mounted() {
    this.updateList();
  },
  watch: {
    $route() {
      this.updateList();
    },
  },
  methods: {
    routeTo(pRouteTo) {
      if (this.breadcrumbList[pRouteTo].link)
        this.$router.push(this.breadcrumbList[pRouteTo].link);
    },
    routeBack() {
      this.$router.go(-1);
    },
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
      if (this.breadcrumbList) {
        if (this.breadcrumbList.length > 1) {
          this.isActive = true;
        } else this.isActive = false;
      }
    },
  },
};
</script>

<style scoped>
ul {
  display: flex;
  justify-content: center;
  list-style-type: none;
}

ul > li {
  display: flex;
  float: left;
  width: auto;
  color: default;
  font-weight: bolder;
  cursor: default;
  align-items: center;
}

ul > li:not(:last-child)::after {
  content: "/";
  float: right;
  color: black;
  cursor: default;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.linked {
  cursor: pointer;
  font-weight: 500;
  color: red;
  font-style: italic;
}
</style>
