<template>
  <div class="relative flex justify-between">
    <button
      class="relative flex flex-1 items-center focus:outline-none select-none"
      @click="open = !open"
    >
      <slot name="button"></slot>
      <p v-if="arrow">
        <font-awesome-icon
          :icon="['fas', 'caret-down']"
          size="1x"
          class="text-black ml-2"
        />
      </p>
    </button>

    <!-- to close when clicked on space around it-->
    <button
      class="fixed inset-0 h-full w-full cursor-default focus:outline-none"
      v-if="open"
      @click="open = false"
      tabindex="-1"
    ></button>
    <!--dropdown menu-->
    <transition
      enter-active-class="transition-all duration-200 ease-out"
      leave-active-class="transition-all duration-750 ease-in"
      enter-class="opacity-0 scale-75"
      enter-to-class="opacity-100 scale-100"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-75"
    >
      <div
        class="absolute shadow-lg border rounded py-1 px-2 text-sm mt-4 bg-white z-10"
        :class="placement === 'right' ? 'right-0' : 'left-0'"
        v-if="open"
        @click="open = false"
        @mouseleave="open = false"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    arrow: Boolean,
  },

  mounted() {
    const onEscape = (e) => {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false;
      }
    };

    document.addEventListener("keydown", onEscape);

    this.$once("hook:beforeDestroy", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
};
</script>
