<template>
  <div class="vlabeledit w-full">
    <div
      class="vlabeledit-label align-middle border border-gray-300 rounded-md px-1 cursor-pointer"
      @click="onLabelClick"
      v-if="!edit"
    >
      {{ vlabel }}
      <font-awesome-icon :icon="['fas', 'pen']" size="sm" class="ml-2" />
    </div>
    <input
      v-if="edit"
      v-model="label"
      v-on:blur="updateText"
      ref="labeledit"
      :placeholder="vplaceholder"
      class="vlabeledit-input flex-1 px-1"
    />
  </div>
</template>
<script>
export default {
  name: "LabelEdit",
  data: function() {
    return {
      edit: false, // define whether it is in edit mode or not
      label: "", // v-bind data model for input text
      empty: "", // empty place holder .. replace with your own localization for default
      extension: this.file
        ? "." + this.text.split(".")[this.text.split(".").length - 1]
        : undefined,
    };
  },
  props: {
    file: Boolean,
    folder: Boolean,
    text: String,
    placeholder: String,
  },
  methods: {
    initText: function() {
      if (this.text == "" || this.text == undefined) {
        this.label = this.vlabel;
      } else {
        this.label = this.text;
      }
    },
    // when the div label got clicked and trigger the text box
    onLabelClick: function() {
      this.edit = true;
      if (this.file) {
        this.label = this.text
          .split(".")
          .slice(0, -1)
          .join();
      } else if (this.folder) {
        this.label = this.text.slice(0, -1);
      } else {
        this.label = this.text;
      }
    },
    // trigger when textbox got lost focus
    updateText: function() {
      this.edit = false;
      if (this.file) {
        this.label = this.label + this.extension;
      } else if (this.folder) {
        this.label = this.label + "/";
      }
      this.$emit("text-updated", this.label);
    },
  },
  computed: {
    vplaceholder: function() {
      // check if the placeholder is undefined or empty
      if (this.placeholder == undefined || this.placeholder == "") {
        // if it is empty or undefined, pre-populate with built-in place holder text
        return this.empty;
      } else {
        return this.placeholder;
      }
    },
    vlabel: function() {
      // after text has been updated
      // return text value or place holder value depends on value of the text
      if (this.text == undefined || this.text == "") {
        return this.vplaceholder;
      } else {
        return this.label;
      }
    },
  },
  mounted: function() {
    // initiate the label view
    this.initText();
  },
  updated: function() {
    var ed = this.$refs.labeledit;
    if (ed != null) {
      ed.focus();
    }
  },
  watch: {
    text: function(value) {
      if (value == "" || value == undefined) {
        this.label = this.vplaceholder;
      } else {
        this.label = value;
      }
    },
  },
};
</script>
