import moment from "moment";

export const getIconName = function(name) {
  if (name.includes(".pdf")) {
    return "file-pdf";
  } else if (name.includes(".doc")) {
    return "file-word";
  } else if (name.includes(".ppt")) {
    return "file-powerpoint";
  } else if (name.includes(".xls")) {
    return "file-excel";
  } else if (name.includes(".mp4")) {
    return "film";
  } else if (name.includes(".mp3") || name.includes(".wav")) {
    return "music";
  }
};

export const getIconCol = function(name) {
  if (name.includes(".pdf")) {
    return "red";
  } else if (name.includes(".doc")) {
    return "blue";
  } else if (name.includes(".ppt")) {
    return "orangered";
  } else if (name.includes(".xls")) {
    return "green";
  } else if (name.includes(".mp4")) {
    return "black";
  } else if (name.includes(".mp3") || name.includes(".wav")) {
    return "black";
  } else {
    return "grey";
  }
};

export const formatDate = function(date) {
  return moment
    .utc(date)
    .local()
    .format("DD MMM YYYY hh:mmA");
};
